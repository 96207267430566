import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Landing.css';
import openCrimeBG from './resources/openCrimeBG.mp4';
import logo from './resources/landingLogo.gif';

function Landing() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    console.log('Site entered.');
    // Navigate to App.js
    navigate('/app');
  };

  return (
    <div className='home-container'>
      <video autoPlay loop muted className='bg-video'>
        <source src={openCrimeBG} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <img src={logo} alt='landing logo' className='landing-logo' />
      <button className='LandingButton' onClick={handleButtonClick}>
        ENTER SITE
      </button>
    </div>
  );
}

export default Landing;