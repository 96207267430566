import React, { useState } from 'react';
import './TextBox.css'; // Import the CSS file for styling

const TextBox = ({ value, onChange }) => {
  const characterLimit = 120;

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      onChange(inputText);
    }
  };

  const remainingCharacters = characterLimit - value.length;

  return (
    <div className="textbox-container">
      <textarea
        className="textbox"
        placeholder="Type here..."
        value={value}
        onChange={handleInputChange}
      />
      <div className="character-count">{`${remainingCharacters}/${characterLimit}`}</div>
    </div>
  );
};

export default TextBox;
