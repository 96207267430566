import React, { useEffect, useRef, useState } from 'react';
import './SubmitReportPopup.css';
import Draggable from 'react-draggable';
import Dropdown from './Dropdown';
import TextBox from './TextBox';
import ReCAPTCHA from 'react-google-recaptcha';
import SearchBar2 from './SearchBar2';

const SubmitReportPopup = ({ handleClose }) => {
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [selectedCrime, setSelectedCrime] = useState(null);
  const popupRef = useRef(null);
  const [crimeDescription, setCrimeDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isCaptchaCompleted, setCaptchaCompleted] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [reportSubmitted, setReportSubmitted] = useState(false);

  useEffect(() => {
    const storedLocation = JSON.parse(getCookie('currentLocation'));
    console.log('Stored Location in useEffect:', storedLocation);
    setCurrentLocation(storedLocation);
  }, []);

  useEffect(() => {
    centerPopup();
    const handleResize = () => centerPopup();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const centerPopup = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = popupRef.current.offsetWidth;
    const popupHeight = popupRef.current.offsetHeight;

    const top = screenHeight / 2 - popupHeight / 2;
    const left = screenWidth / 2 - popupWidth / 2;

    popupRef.current.style.top = `${top}px`;
    popupRef.current.style.left = `${left}px`;
  };

  const crimeOptions = [
    'Assault',
    'Burglary',
    'Robbery',
    'Shots Fired',
    'Suspicious Activity',
    'Motor Vehicle Theft',
    'Homicide',
    'Arson',
    'Other',
  ];

  const reportCrimeEndpoint = 'https://opencrime.io/api/submitReport';

  const handleButtonClick = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');

    const userId = getUserIdFromCookie();

    if (!userId) {
      setErrorMessage('Please sign in before submitting a report.');
      console.error('User ID is undefined. Please check getUserIdFromCookie implementation.');
      return;
    }

    if (!isCaptchaCompleted) {
      setErrorMessage('Please complete the captcha.');
      console.error('Captcha unresolved');
      return;
    }

    // Fetch currentLocation from the cookie when the button is clicked
    const storedLocation = JSON.parse(getCookie('currentLocation'));

    console.log('Stored Location in handleButtonClick:', storedLocation);
    console.log('Selected Coordinates in handleButtonClick:', selectedCoordinates);

    const isStoredLocationValid = Array.isArray(storedLocation) && storedLocation.length === 2;

    const reportData = {
      rpt_type: selectedCrime,
      rpt_long: useCurrentLocation ? (isStoredLocationValid ? storedLocation[0] : null) : (selectedCoordinates ? selectedCoordinates.longitude : null),
      rpt_lat: useCurrentLocation ? (isStoredLocationValid ? storedLocation[1] : null) : (selectedCoordinates ? selectedCoordinates.latitude : null),
      rpt_dt: formattedDate,
      usr_id: userId,
      rpt_desc: crimeDescription,
    };

    console.log('Report Data:', reportData);

    fetch(reportCrimeEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reportData),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);

        if (data.success) {
          console.log('Crime report submitted successfully!');
          setErrorMessage('');
          setReportSubmitted(true);
        } else {
          setErrorMessage('Report submission failed.');
        }
      })
      .catch(error => {
        console.error('Error submitting crime report:', error);
      });
  };

  const getUserIdFromCookie = () => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'usr_id') {
        return value;
      }
    }
    return null;
  };

  const onChangeCaptcha = () => {
    setCaptchaCompleted(true);
  };

  const handleSearch = (coordinates) => {
    setSelectedCoordinates({
      longitude: coordinates[0],
      latitude: coordinates[1],
    });
  };

  const renderOriginalContent = () => (
    <div className="Popup" ref = {popupRef}>
      <p className="Title">Submit a Crime Report.</p>
      <Dropdown
        className="Dropdown"
        items={crimeOptions}
        onSelect={setSelectedCrime}
      />
      <p2 className="Subtitle">Location</p2>
      <SearchBar2
        className="SearchBar2"
        onSearch={(coordinates) => handleSearch(coordinates)}
      />
      <label style={{ fontSize: '14px' }} className="labelFontSize">
        <input
          type="checkbox"
          checked={useCurrentLocation}
          onChange={() => setUseCurrentLocation(!useCurrentLocation)}
        />
        Use Current Location
      </label>
      <p2 className="Subtitle">Add a Description (Optional)</p2>
      <TextBox
        className="TextBox"
        value={crimeDescription}
        onChange={setCrimeDescription}
      />
      <div className="CloseButton" onClick={handleClose}>
        x
      </div>
      <ReCAPTCHA sitekey="6LfuRgwpAAAAACz6Pa2PZSmA65Y9WuFC7Rdk6wp6" onChange={onChangeCaptcha} />
      <button className="SubmitButton" onClick={handleButtonClick}>
        Submit Report
      </button>
      <div className="ErrorMessage">{errorMessage}</div>
    </div>
  );

  const renderSuccessMessage = () => (
    <div ref = {popupRef}>
      <p>Your report has been submitted.</p>
      <div className="CloseButton" onClick={handleClose}>
        x
      </div>
    </div>
  );

  return (
    <Draggable>
      <div className={`Popup ${reportSubmitted ? 'Submitted' : ''}`} ref = {popupRef}>
        {reportSubmitted ? renderSuccessMessage() : renderOriginalContent()}
      </div>
    </Draggable>
  );
};

export default SubmitReportPopup;

const getCookie = (name) => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};