import React, { useEffect, useRef, useState } from 'react';
import './SISUPopUp.css';
import Draggable from 'react-draggable';
import ReCAPTCHA from 'react-google-recaptcha';

const CreateAccount = ({ handleClose }) => {
  const popupRef = useRef(null);
  const onChange = () => {};
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [verifyPasswordText, setVerifyPasswordText] = useState('');
  const characterLimit = 40;

  const [isCreateAccount, setIsCreateAccount] = useState(false);

  const handleButtonClick = () => {
    // Add your logic for handling button click here
    console.log('Button clicked!');
    // Toggle between Sign In and Create Account forms
    setIsCreateAccount(!isCreateAccount);
  };

  const handleEmailInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setEmailText(inputText);
    }
  };

  const handlePasswordInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setPasswordText(inputText);
    }
  };

  const handleVerifyPasswordInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setVerifyPasswordText(inputText);
    }
  };

  const remainingEmailCharacters = characterLimit - emailText.length;
  const remainingPasswordCharacters = characterLimit - passwordText.length;
  const remainingVerifyPasswordCharacters = characterLimit - verifyPasswordText.length;

  useEffect(() => {
    const centerPopup = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const popupWidth = popupRef.current.offsetWidth;
      const popupHeight = popupRef.current.offsetHeight;

      const top = screenHeight / 2 - popupHeight / 2;
      const left = screenWidth / 2 - popupWidth / 2;

      popupRef.current.style.top = `${top}px`;
      popupRef.current.style.left = `${left}px`;
    };

    // Call the centerPopup function when the component mounts
    centerPopup();

    // Update the position on window resize
    const handleResize = () => {
      centerPopup();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Draggable>
      <div className="PopupACC" ref={popupRef}>
        {/* Add your content for the popup */}
        {isCreateAccount ? (
          <>
            <p className="TitleACC">CREATE ACCOUNT</p>
            <div className="textbox-containerACCemail">
              <textarea
                className="textboxACCemail"
                placeholder="Enter Email"
                value={emailText}
                onChange={handleEmailInputChange}
              />
            </div>
            <div className="textbox-containerACCpass">
              <textarea
                className="textboxACCpass"
                placeholder="Create a Password"
                value={passwordText}
                onChange={handlePasswordInputChange}
              />
            </div>
            <div className="textbox-containerACCverifyPass">
              <textarea
                className="textboxACCverifyPass"
                placeholder="Verify Password"
                value={verifyPasswordText}
                onChange={handleVerifyPasswordInputChange}
              />
            </div>
            <div className="captcha-container">
              <ReCAPTCHA sitekey="your-recaptcha-sitekey" onChange={onChange} />
            </div>
            <button className="SubmitButtonACC" onClick={handleButtonClick}>
              CREATE ACCOUNT
            </button>
          </>
        ) : (
          <>
            <p className="TitleSISU">SIGN IN</p>
            {/* Add your existing Sign In content */}
            {/* ... */}
          </>
        )}

        <div className="CloseButton" onClick={handleClose}>
          x
        </div>

        <hr className="button-line"></hr>

        <button className="SignInButtonSISU" onClick={handleButtonClick}>
          {isCreateAccount ? 'CANCEL' : 'SIGN IN'}
        </button>

        <button className="CreateAccountButtonSISU" onClick={handleButtonClick}>
          CREATE AN ACCOUNT
        </button>
      </div>
    </Draggable>
  );
};

export default CreateAccount;