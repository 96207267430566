import React, { useEffect, useRef, useState } from 'react';
import './SISUPopUp.css'; // Import the CSS file
import Draggable from 'react-draggable';
import ReCAPTCHA from 'react-google-recaptcha';
import CreateAccount from './CreateAccount';
import SignedIn from './SignedIn';
import Cookies from 'js-cookie';

const SISUPopUp = ({ handleClose, setSignedIn }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCreateAccountVisible, setCreateAccountVisible] = useState(false);
  const [isAccountCreated, setAccountCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSignedIn] = useState(false);

  const popupRef = useRef(null);
  const onChange = () => {};
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [verifyPasswordText, setVerifyPasswordText] = useState('');
  const characterLimit = 40;

  const [isCaptchaCompleted, setCaptchaCompleted] = useState(false);

  const handleButtonClick = () => {
    console.log('Button clicked!');
  
    const checkEmailEndpoint = 'https://opencrime.io/api/checkEmail';
  
    if (emailText.trim() === '' || passwordText.trim() === '') {
      console.error('Email and password are required.');
      setErrorMessage('Email and password are required.');
      return;
    }
  
    if (!isCaptchaCompleted) {
      setErrorMessage('Please complete the captcha.');
      return; // Don't proceed with the API call
    }
  
    fetch(checkEmailEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: emailText,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
  
        if (data.emailExists && isCreateAccountVisible) {
          setErrorMessage('Email is already in use.');
        } else {
          const apiEndpoint = isCreateAccountVisible
            ? 'https://opencrime.io/api/register'
            : 'https://opencrime.io/api/login';
  
          fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              username: emailText,
              password: passwordText,
            }),
          })
            .then(response => response.json())
            .then(data => {
              console.log(data);
  
              if (data.success) {
                const handleUserAuthentication = (usr_id) => {
                  // Set the cookie only if usr_id is defined
                  if (usr_id !== undefined) {
                    Cookies.set('usr_id', usr_id, { path: '/' });
                    Cookies.set('isSignedIn', 'true', { path: '/' });
                  } else {
                    console.error('usr_id is undefined.');
                  }
                };
  
                if (isCreateAccountVisible) {
                  setAccountCreated(true);
                  setErrorMessage('');
  
                  // Fetch the usr_id from the database
                  fetch('https://opencrime.io/api/getUserId', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      username: emailText,
                    }),
                  })
                    .then(response => response.json())
                    .then(data => {
                      console.log('Get UserId Response:', data);
                  
                      if (data.success) {
                        handleUserAuthentication(data.usr_id);
                      } else {
                        console.error('Error getting usr_id:', data.error);
                      }
                    })
                    .catch(error => {
                      console.error('Error getting usr_id:', error);
                    });
                } else {
                  setErrorMessage('');
                  setSignedIn(true);
  
                  // Use the usr_id directly from the response
                  handleUserAuthentication(data.userId);
                }
              } else {
                setErrorMessage('Account creation or sign-in failed. Please try again.');
              }
            })
            .catch(error => {
              console.error('Error:', error);
            });
        }
      })
      .catch(error => {
        console.error('Error checking email:', error);
      });
  };

  const toggleCreateAccount = () => {
    setCreateAccountVisible(!isCreateAccountVisible);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setCreateAccountVisible(false);
    setEmailText('');
    setPasswordText('');
    setVerifyPasswordText('');
  };

  const handleEmailInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setEmailText(inputText);
    }
  };

  const handlePasswordInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setPasswordText(inputText);
    }
  };

  const handleVerifyPasswordInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= characterLimit) {
      setVerifyPasswordText(inputText);
    }
  };

  const remainingEmailCharacters = characterLimit - emailText.length;
  const remainingPasswordCharacters = characterLimit - passwordText.length;

  // ... (previous functions)

  const onChangeCaptcha = () => {
    // Set the CAPTCHA completion state to true
    setCaptchaCompleted(true);
  };


  useEffect(() => {
    const centerPopup = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const popupWidth = popupRef.current.offsetWidth;
      const popupHeight = popupRef.current.offsetHeight;

      const top = screenHeight / 2 - popupHeight / 2;
      const left = screenWidth / 2 - popupWidth / 2;

      popupRef.current.style.top = `${top}px`;
      popupRef.current.style.left = `${left}px`;
    };

    // Call the centerPopup function when the component mounts
    centerPopup();

    // Update the position on window resize
    const handleResize = () => {
      centerPopup();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
<Draggable>
    <div className="PopupSISU" ref={popupRef}>
      <p className="TitleSISU">{isCreateAccountVisible ? 'CREATE ACCOUNT' : 'SIGN IN'}</p>

      <div className="textbox-containerSISUemail">
        <textarea
          className="textboxSISUemail"
          placeholder="Enter Email"
          value={emailText}
          onChange={handleEmailInputChange}
        />
      </div>

      <div className="textbox-containerSISUpass">
        <textarea
          className="textboxSISUpass"
          placeholder={isCreateAccountVisible ? 'Create Password' : 'Enter Password'}
          value={passwordText}
          onChange={handlePasswordInputChange}
        />
      </div>

      {isCreateAccountVisible && (
        <div className="textbox-containerSISUpass">
          <textarea
            className="textboxSISUpass"
            placeholder="Verify Password"
            value={verifyPasswordText}
            onChange={handleVerifyPasswordInputChange}
          />
        </div>
      )}

      <div className="captcha-container">
        <ReCAPTCHA
          sitekey="6LfuRgwpAAAAACz6Pa2PZSmA65Y9WuFC7Rdk6wp6"
          onChange={onChangeCaptcha}
        />
      </div>

      <button className="SignInButtonSISU" onClick={handleButtonClick}>
        {isCreateAccountVisible ? 'CREATE ACCOUNT' : 'SIGN IN'}
      </button>

        {errorMessage && !isAccountCreated && (
          <p className="ErrorMessage">{errorMessage}</p>
        )}

        {isAccountCreated && (
          <p className="AccountCreatedMessage">Account created successfully!</p>
        )}


      <div className="CloseButton" onClick={handleClose}>
        x
      </div>

      <hr className="button-line"></hr>

      <button className="SubmitButtonSISU" onClick={toggleCreateAccount}>
        {isCreateAccountVisible ? 'SIGN IN' : 'CREATE AN ACCOUNT'}
      </button>

      {isPopupVisible && isCreateAccountVisible && <CreateAccount handleClose={closePopup} />}
    </div>
  </Draggable>
  );
};

export default SISUPopUp;