import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import 'maplibre-gl/dist/maplibre-gl.css';
import './SearchBar2.css';

const SearchBar2 = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const apiKey = 'uRyomqiL7WA1gAWsFiHK';

  useEffect(() => {
    // Fetch initial suggestions or perform any other side effects here
    fetchSuggestions(searchTerm);
  }, [searchTerm]);

  const fetchSuggestions = async (value) => {
    try {
      const response = await fetch(
        `https://api.maptiler.com/geocoding/${encodeURIComponent(value)}.json?key=${apiKey}`
      );
      const data = await response.json();

      if (data.features && data.features.length > 0) {
        const newSuggestions = data.features.map((feature) => ({
          label: feature.place_name || 'Place name not available',
          coordinates: feature.geometry.coordinates,
        }));

        setSuggestions(newSuggestions);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.label;
  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">{suggestion.label}</div>
  );

  const inputProps = {
    placeholder: 'Search Location...',
    value: searchTerm,
    onChange: (e, { newValue }) => setSearchTerm(newValue),
  };

  return (
    <div className="SearchBar2">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => fetchSuggestions(value)}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(e, { suggestion }) => {
          console.log('Selected Coordinates:', suggestion.coordinates);
          onSearch(suggestion.coordinates);
        }}
      />
    </div>
  );
};

export default SearchBar2;