import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import './Map.css';
import icons from './resources/icons.jpg';
import 'maplibre-gl/dist/maplibre-gl.css';
import Cookies from 'js-cookie';
import { useMap } from './MapContext';

import arsonIcon from './resources/icons/arson.png';
import assaultIcon from './resources/icons/assault.png';
import burglaryIcon from './resources/icons/burglary.png';
import otherIcon from './resources/icons/other.png';
import burglaryCarIcon from './resources/icons/burglarycar.png';
import homicideIcon from './resources/icons/homicide.png';
import motorVehicleTheftIcon from './resources/icons/motorvehicletheft.png';
import robberyIcon from './resources/icons/robbery.png';
import shotsFiredIcon from './resources/icons/shotsfired.png';
import suspiciousIcon from './resources/icons/suspicious.png';

const Map = ({ selectedCrimes }) => {
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const { selectedCoordinates } = useMap();

  const initializeMap = async () => {
    try {
      const response = await fetch(
        'https://api.maptiler.com/maps/727645e3-65d3-4d8c-bdbd-92ec2f87d50d/style.json?key=uRyomqiL7WA1gAWsFiHK'
      );
      const style = await response.json();

      const newMap = new maplibregl.Map({
        container: mapContainerRef.current,
        style: style,
        center: [0, 0],
        zoom: 1,
      });

      newMap.addControl(new maplibregl.NavigationControl({ showCompass: true }), 'top-right');

      let userLocation = [0, 0]; // Default location

      if ('geolocation' in navigator) {
        try {
          const storedLocation = Cookies.get('currentLocation');
          if (storedLocation) {
            userLocation = JSON.parse(storedLocation);
          } else {
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { longitude, latitude } = position.coords;
            userLocation = [longitude, latitude];
            Cookies.set('currentLocation', JSON.stringify(userLocation), { expires: 14 });
          }
        } catch (error) {
          console.warn('Error getting geolocation:', error.message);
        }
      } else {
        console.warn('Geolocation is not supported by this browser.');
      }

      if (!selectedCoordinates) {
        newMap.jumpTo({
          center: userLocation,
          zoom: 15,
          duration: 1000,
        });
      } else {
        newMap.jumpTo({
          center: selectedCoordinates,
          zoom: 15,
          duration: 1000,
        });
      }

      setMap(newMap);

      const reportDataResponse = await fetch('https://opencrime.io/api/getReports');
      const reportData = await reportDataResponse.json();

      if (selectedCrimes.length === 0) {
        return;
      }

      const geoJsonFeatures = reportData
        .filter((report) => selectedCrimes.includes(report.rpt_type))
        .map((report) => {
          let iconPath;
          switch (report.rpt_type) {
            case 'Arson':
              iconPath = arsonIcon;
              break;
            case 'Assault':
              iconPath = assaultIcon;
              break;
            case 'Burglary':
              iconPath = burglaryIcon;
              break;
            case 'Homicide':
              iconPath = homicideIcon;
              break;
            case 'Motor Vehicle Theft':
              iconPath = motorVehicleTheftIcon;
              break;
            case 'Robbery':
              iconPath = robberyIcon;
              break;
            case 'Shots Fired':
              iconPath = shotsFiredIcon;
              break;
            case 'Suspicious Activity':
              iconPath = suspiciousIcon;
              break;
            default:
              iconPath = otherIcon;
              break;
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [report.rpt_long, report.rpt_lat],
            },
            properties: {
              title: report.rpt_type,
              date: `Reported at ${report.rpt_dt}`,
              description: report.rpt_desc,
              icon: iconPath,
              iconSize: [30, 30],
            },
          };
        });

      const geoJsonData = {
        type: 'FeatureCollection',
        features: geoJsonFeatures,
      };

      newMap.on('load', function () {

      geoJsonData.features.forEach((feature) => {
        const markerElement = document.createElement('div');
        markerElement.className = 'marker';
        markerElement.style.width = '30px';
        markerElement.style.height = '30px';

        switch (feature.properties.title) {
          case 'Arson':
            markerElement.classList.add('arson-marker');
            break;
          case 'Assault':
            markerElement.classList.add('assault-marker');
            break;
          case 'Burglary':
            markerElement.classList.add('burglary-marker');
            break;
          case 'Homicide':
            markerElement.classList.add('homicide-marker');
            break;
          case 'Motor Vehicle Theft':
            markerElement.classList.add('motor-vehicle-theft-marker');
            break;
          case 'Robbery':
            markerElement.classList.add('robbery-marker');
            break;
          case 'Shots Fired':
            markerElement.classList.add('shots-fired-marker');
            break;
          case 'Suspicious Activity':
            markerElement.classList.add('suspicious-activity-marker');
            break;
          default:
            markerElement.classList.add('other-marker');
        }

        markerElement.addEventListener('click', () => {
          window.alert(`Clicked on ${feature.properties.title} marker`);
        });

        new maplibregl.Marker(markerElement)
          .setLngLat(feature.geometry.coordinates)
          .setPopup(
            new maplibregl.Popup().setHTML(
              `<h3>${feature.properties.title}</h3>
              <p>${feature.properties.date}</p>
              <p>${feature.properties.description}</p>`
            )
          )
          .addTo(newMap);
                });
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    initializeMap();
  }, [selectedCrimes, selectedCoordinates]);

  return (
    <div className="map-container-wrapper" style={{ position: 'relative' }}>
      <div
        className="map-container"
        style={{
          position: 'absolute',
          width: '70vw',
          height: '88vh',
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          borderRadius: '10px',
        }}
      >
        <img className="icons" src={icons} alt="placeholder" />
        <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
};

export default Map;
