import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './LocationPopup.css'; // Assuming you have a CSS file for styling

const LocationPopup = ({ handleClose }) => {
  const [isPopupVisible, setPopupVisible] = useState(true); // Starts as visible
  const popupRef = useRef(null);

  useEffect(() => {
    const centerPopup = () => {
      if (popupRef.current) {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const popupWidth = popupRef.current.offsetWidth;
        const popupHeight = popupRef.current.offsetHeight;

        const top = screenHeight / 2 - popupHeight / 2;
        const left = screenWidth / 2 - popupWidth / 2;

        popupRef.current.style.top = `${top}px`;
        popupRef.current.style.left = `${left}px`;
      }
    };

    // Call the centerPopup function after the initial render
    centerPopup();

    // Update the position on window resize
    const handleResize = () => {
      centerPopup();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkLocationCookie = () => {
    // Check if the currentLocation cookie is present
    const currentLocationCookie = document.cookie.split('; ').find(row => row.startsWith('currentLocation='));
    if (currentLocationCookie) {
      setPopupVisible(false);
      handleClose();
    }
  };

  // Check for the location cookie whenever the component renders
  useEffect(() => {
    checkLocationCookie();
  });

  return (
    <>
      {isPopupVisible && (
        <Draggable>
          <div className="LocationPopup" ref={popupRef}>
            <p>Please allow location access <br />to enjoy site functionality.</p>
            <div className="CloseButton" onClick={handleClose}>
              x
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default LocationPopup;