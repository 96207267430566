import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import maplibregl from 'maplibre-gl';
import { GeocodingControl } from '@maptiler/geocoding-control/maplibregl';
import 'maplibre-gl/dist/maplibre-gl.css';
import './SearchBar.css';
import Cookies from 'js-cookie';
import { useMap } from './MapContext';

const SearchBar = ({ onSearchButtonClick, map }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const apiKey = 'uRyomqiL7WA1gAWsFiHK';
  const { setCoordinates } = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const gc = new GeocodingControl({ apiKey, maplibregl });

    gc.on('results', (results) => {
      const newSuggestions = results.features.map((feature) => ({
        label: feature.place_name || 'Place name not available',
        coordinates: feature.geometry.coordinates,
      }));

      setSuggestions(newSuggestions);
    });

    map.addControl(gc);

    return () => {
      gc.remove();
    };
  }, [map, apiKey]);

  const handleSearch = () => {
    if (selectedCoordinates) {
      console.log('Selected Coordinates:', selectedCoordinates);

      // Use selectedCoordinates directly
      setCoordinates(selectedCoordinates);
    } else if (searchTerm.trim() !== '') {
      console.log('Search term entered manually:', searchTerm);
    } else {
      console.log('No suggestion selected or no coordinates available.');
    }
  };

  

  const getSuggestionValue = (suggestion) => {
    return suggestion.label || '';
  };

  const renderSuggestion = (suggestion) => {
    return (
      <div onClick={() => setSelectedCoordinates(suggestion.coordinates)} className="suggestion-item">
        {suggestion.label}
      </div>
    );
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    let data = null;

    try {
      const response = await fetch(`https://api.maptiler.com/geocoding/${encodeURIComponent(value)}.json?key=${apiKey}&types=address&autocomplete=false&fuzzyMatch=false&limit=5`);
      data = await response.json();

      if (data.features && data.features.length > 0) {
        const newSuggestions = data.features.map((feature) => ({
          label: feature.place_name || 'Place name not available',
          coordinates: feature.geometry.coordinates,
        }));

        setSuggestions(newSuggestions);
      } else {
        setSuggestions([{ label: 'No results found', coordinates: null }]);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const inputProps = {
    placeholder: 'Search Location...',
    value: searchTerm,
    onChange: (e, { newValue }) => setSearchTerm(newValue),
  };

  return (
    <div className="SearchBar">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        className="custom-autosuggest"
      />
      <button className="SearchButton" onClick={handleSearch}>
        SEARCH
      </button>
    </div>
  );
};

export default SearchBar;