import React, { useEffect, useState } from 'react';
import './Header.css';
import openGIF from './resources/openGIF.gif';
import SignInSignUp from './resources/SignInSignUp2.gif';
import SignedIn from './resources/SignedIn.gif';
import SISUPopUp from './SISUPopUp';
import SignedInPopUp from './SignedIn';
import Cookies from 'js-cookie';

const Header = () => {
  const [isSignedIn, setSignedIn] = useState(null);
  const [isSISUPopupVisible, setSISUPopupVisible] = useState(false);
  const [isSignedInPopupVisible, setSignedInPopupVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isSignedInCookie = Cookies.get('isSignedIn');
        setSignedIn(isSignedInCookie === 'true');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const handleCookieChange = () => {
      fetchData();
    };

    window.addEventListener('storage', handleCookieChange);

    return () => {
      window.removeEventListener('storage', handleCookieChange);
    };
  }, []);

  const openSISUPopup = () => {
    setSISUPopupVisible(true);
  };

  const openSignedInPopup = () => {
    setSignedInPopupVisible(true);
  };

  const closePopup = () => {
    setSISUPopupVisible(false);
    setSignedInPopupVisible(false);
  };

  const handleSignOut = () => {
    // Update the isSignedIn state when signing out
    setSignedIn(false);
  };

  return (
    <header className="header">
      <nav>
        <ul className="header-links">
          <img className="logoImage" src={openGIF} alt="Placeholder" />
        </ul>
        <ul className="header-links-right">
          {isSignedIn ? (
            <img className="signedin-link" src={SignedIn} alt="Signed In Icon" onClick={openSignedInPopup} />
          ) : (
            <img className="login-link" src={SignInSignUp} alt="Sign In/Sign Up Icon" onClick={openSISUPopup} />
          )}
        </ul>
        {isSISUPopupVisible && !isSignedIn && <SISUPopUp handleClose={closePopup} setSignedIn={setSignedIn} />}
        {isSignedInPopupVisible && isSignedIn && (
          <SignedInPopUp handleClose={closePopup} handleSignOut={handleSignOut} />
        )}
      </nav>
    </header>
  );
};

export default Header;