import React, { useState } from 'react';
import Map from './Map';
import Header from './Header';
import './App.css';
import SearchBar from './SearchBar';
import Filter from './FilterSection';
import dancingGIF from './resources/dancingGIF.gif';
import Cookies from 'js-cookie';
import { MapProvider } from './MapContext';
import LocationPopup from './LocationPopup';

function App() {
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [mapLocation, setMapLocation] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [isLocationPopupVisible, setLocationPopupVisible] = useState(true);

  const handleSearch = ({ coordinates }) => {
    // Update the map location in the state
    setMapLocation(coordinates);
  };

  const handleCloseLocationPopup = () => {
    setLocationPopupVisible(false);
    // Perform any other actions needed when closing the popup
  };

  return (
    <MapProvider>
    <div className='body'>
    {isLocationPopupVisible && <LocationPopup handleClose={handleCloseLocationPopup} />}
      <Header className='Header' />
      <Map
        className='Map'
        selectedCrimes={selectedCrimes}
        selectedCoordinates={selectedCoordinates}
      />
      <SearchBar onSearch={handleSearch} className='SearchBar' />
      <Filter className='FilterSection' selectedCrimes={selectedCrimes} setSelectedCrimes={setSelectedCrimes}/>
    </div>
    </MapProvider>
  );
}

export default App;