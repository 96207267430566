import React, { useState, useEffect } from 'react';
import './FilterSection.css';
import SubmitReportPopup from './SubmitReportPopup';

const crimeTypes = [
  'All Reports',
  'Assault',
  'Burglary',
  'Robbery',
  'Shots Fired',
  'Suspicious Activity',
  'Motor Vehicle Theft',
  'Homicide',
  'Arson',
  'Other'
];

const FilterSection = ({ selectedCrimes, setSelectedCrimes }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    // Initialize selectedCrimes with all available crime types
    setSelectedCrimes([...crimeTypes]);
  }, [setSelectedCrimes]);

  const handleCrimeToggle = (crime) => {
    if (crime === 'All Reports') {
      // If 'All Reports' is clicked, toggle all crimes
      setSelectedCrimes(selectedCrimes.includes('All Reports') ? [] : [...crimeTypes]);
    } else {
      // If other crimes are clicked, toggle the specific crime
      const updatedCrimes = selectedCrimes.includes(crime)
        ? selectedCrimes.filter((selectedCrime) => selectedCrime !== crime)
        : [...selectedCrimes, crime];
      setSelectedCrimes(updatedCrimes.filter((c) => c !== 'All Reports')); // Ensure 'All Reports' is not duplicated
    }
  };

  const handleReportCrime = () => {
    console.log('Reporting Crime:');
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div className="FilterSection">
      <h2>Crime Filters</h2>
      <div className="CrimeItemsContainer">
        {crimeTypes.map((crime) => (
          <div key={crime} className="CrimeItem">
            <input
              type="checkbox"
              id={crime}
              checked={selectedCrimes.includes(crime)}
              onChange={() => handleCrimeToggle(crime)}
            />
            <label htmlFor={crime}>{crime}</label>
          </div>
        ))}
      </div>

      <div className='reportContainer-Container'>
        <div className='reportContainer'>
          <p className='crimeText'>Witnessed any crimes lately? Seen anything suspicious?</p>
          <button className="ReportCrimeButton" onClick={handleReportCrime}>
            SUBMIT A REPORT
          </button>
        </div>
      </div>
      {isPopupVisible && <SubmitReportPopup handleClose={closePopup} />}
    </div>
  );
};

export default FilterSection;