import React, { createContext, useContext, useState } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);

  const setCoordinates = (coordinates) => {
    console.log('Setting coordinates:', coordinates);
    setSelectedCoordinates(coordinates);
  };

  return (
    <MapContext.Provider value={{ selectedCoordinates, setCoordinates }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMap must be used within a MapProvider');
  }
  return context;
};