import React, { useEffect, useRef, useState } from 'react';
import './SignedIn.css'; // Import the CSS file
import Draggable from 'react-draggable';
import Cookies from 'js-cookie';

const SignedIn = ({ handleClose, handleSignOut }) => {
  const [isPopupVisible, setPopupVisible] = useState(true); // Assuming it starts as visible
  const [isSignedIn, setSignedIn] = useState(true);
  const popupRef = useRef(null);

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleSignOutLocal = () => {
    handleSignOut(); // Call the callback function from props
    // Delete userId and isSignedIn cookies
    document.cookie = 'userId=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = 'isSignedIn=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';

    Cookies.set('isSignedIn', 'false', { path: '/' });

    // Set usr_id to undefined in cookies
    document.cookie = 'usr_id=undefined; path=/';

    // Debugging: Check if cookies are deleted
    const userIdCookie = document.cookie.split('; ').find(row => row.startsWith('userId='));
    const isSignedInCookie = document.cookie.split('; ').find(row => row.startsWith('isSignedIn='));
    const usrIdCookie = document.cookie.split('; ').find(row => row.startsWith('usr_id='));

    console.log('userId cookie after deletion:', userIdCookie);
    console.log('isSignedIn cookie after deletion:', isSignedInCookie);
    console.log('usr_id cookie after deletion:', usrIdCookie);

    setSignedIn(false);
    setPopupVisible(false);
    handleClose();
  };

  useEffect(() => {
    const centerPopup = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const popupWidth = popupRef.current.offsetWidth;
      const popupHeight = popupRef.current.offsetHeight;

      const top = screenHeight / 2 - popupHeight / 2;
      const left = screenWidth / 2 - popupWidth / 2;

      popupRef.current.style.top = `${top}px`;
      popupRef.current.style.left = `${left}px`;
    };

    // Call the centerPopup function when the component mounts
    centerPopup();

    // Update the position on window resize
    const handleResize = () => {
      centerPopup();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isPopupVisible && (
        <Draggable>
          <div className="PopupSignedIn" ref={popupRef}>
            <p className="TitleSISU">ACCOUNT SETTINGS</p>

            <div className="CloseButton" onClick={closePopup}>
              x
            </div>

            <button className='SignOutButton' onClick={handleSignOutLocal}>
              SIGN OUT
            </button>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default SignedIn;